@use '@/styles/_palettes.module.scss' as palettes;
@use '@/styles/mixins.module' as mixins;
@use '@/styles/_styles.module.scss' as styles;
@use '@/styles/_colors.module.scss' as colors;

.share-pause-resume {
  height: 36px;
  font-size: styles.$share-header-button-font-size;
}

.share-pause-resume-button {
  display: flex;
  padding: 6px 20px 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 6px 12px 6px 8px;

  color: palettes.$palette-coral-red;
  background: none;
  border-radius: styles.$header-button-radius;
  border: 1px solid
    var(--border-destructive, colors.$color-destactive-button-border);
  font-weight: 400;

  &:hover {
    cursor: pointer;
    background-color: colors.$color-destactive-button-hover-black;
  }

  &:active {
    background-color: colors.$color-destactive-button-active-black;
  }
}

.share-pause-resume-button-container {
  font-size: styles.$share-header-button-font-size;
  font-style: normal;
  font-weight: 700;
  line-height: styles.$share-header-button-line-height;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.share-pause-icon {
  @include mixins.header-share-button-style;
  background: palettes.$palette-coral-red;

  mask: url('../../../../assets/share_pause.svg') no-repeat center center /
    contain;
  -webkit-mask: url('../../../../assets/share_pause.svg') no-repeat center
    center / contain;
}

.share-resume-icon {
  @include mixins.header-share-button-style;
  background: palettes.$palette-coral-red;

  mask: url('../../../../assets/share_resume.svg') no-repeat center center /
    contain;
  -webkit-mask: url('../../../../assets/share_resume.svg') no-repeat center
    center / contain;
}
