@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;
@use '@/styles/colors.module' as colors;

.footer-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
  position: fixed;
  bottom: 0;
  width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
  height: styles.$footer-guest-safe-area-height;

  & > div {
    text-align: center;
    margin-bottom: calc(env(safe-area-inset-bottom) - 5px);
    margin: 0.5rem 0 0.7rem 0;
  }

  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用
    gap: 12px;
  }
}

.footer-container-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90px;
}

.footer-container-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-container-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .footer-font-size {
    width: 30px;
    padding: 5px;
  }
}

.tts-button-image {
  width: 30px;
  height: 30px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.tts-button-play-icon {
  mask: url('../../../assets/tts_on.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../assets/tts_on.svg') no-repeat center center /
    contain;
  background: colors.$color-tts-play-button;

  @media (hover: hover) {
    &:enabled:hover {
      background: colors.$color-tts-play-button-hover;
    }
  }

  &:enabled:active {
    background: colors.$color-tts-play-button-active;
  }
}

.tts-button-stop-icon {
  mask: url('../../../assets/tts_off.svg') no-repeat center center / contain;
  -webkit-mask: url('../../../assets/tts_off.svg') no-repeat center center /
    contain;
  background: colors.$color-tts-stop-button;

  @media (hover: hover) {
    &:enabled:hover {
      background: colors.$color-tts-stop-button-hover;
    }
  }

  &:enabled:active {
    background: colors.$color-tts-stop-button-active;
  }
}

.tts-button-container {
  margin: 0;
  display: flex;
  justify-content: center;

  flex-flow: column;
  justify-content: center;
  align-items: center;

  & > button {
    &:disabled {
      opacity: styles.$opacity-conference-button;
    }

    &:enabled:hover {
      cursor: pointer;
    }
  }
}

.tts-disabled-message {
  margin: 0;
  padding-top: 5px;
  font-size: styles.$font-size-caution;
  line-height: styles.$font-size-caution-line-height;
  color: palettes.$palette-true-white;
  opacity: styles.$opacity-conference-button;
}

.lang-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  & select {
    color: palettes.$palette-true-white;
    outline: none;
    border: 2px solid;
    border-color: palettes.$palette-true-white;
    appearance: none;
    -webkit-appearance: none;
    background-color: palettes.$palette-true-black;
    text-align: left;
    text-align-last: center;
    text-overflow: ellipsis;
    line-height: 1;
    font-size: 14px;
    border-radius: 30px;
    min-width: 150px;

    &:disabled {
      opacity: styles.$opacity-conference-select;
    }

    @media screen and (max-width: styles.$mobile-max-width) {
      // スマホ用
      width: 130px;
      min-width: 130px;
    }
  }

  & select:only-child {
    padding: 11px 12px;
  }

  & option {
    background-color: palettes.$palette-true-black;
  }

  & select:hover {
    cursor: pointer;
    background-color: palettes.$palette-dark-charcoal;
  }
}

.tts-disabled-message-none {
  display: none;
}
