@use '@/styles/_mixins.module.scss' as mixins;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;
@use '@/styles/palettes.module' as palettes;

.ui-lang-list-container {
  display: flex;
  flex-direction: column;
  position: relative;
  @include mixins.dialog-scroll-style;
}

ul.ui-lang-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    line-height: 1.4;
    border-top: 1px solid colors.$color-modal-dialog-border;

    & p {
      margin: 0;
      padding: 0 40px 12px 59px;
      font-size: styles.$font-size-caution;
      line-height: styles.$font-size-caution-line-height;
      color: palettes.$palette-true-white;
    }

    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background-color: colors.$color-modal-popup-bg-hover;
      }
    }

    & button {
      font-size: styles.$font-size-language-dialog;
      color: palettes.$palette-true-white;
      width: 100%;
      cursor: pointer;
      padding: 14px 10px 14px 20px;
      line-height: styles.$font-size-language-dialog;
      background: transparent;
      border: none;
    }
  }
}

.language-item-check-icon:before {
  content: url(../../../assets/check_mark.svg);
  position: absolute;
  left: 20px;
  width: 24px;
  pointer-events: none;
}
