@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.language-area-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.language-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用
    max-width: 80px;
  }
}

.language-area-button {
  color: palettes.$palette-true-white;
  display: flex;
  gap: 10px;
  background-color: colors.$color-language-area;
  padding: 7px 30px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;

  border: none;
  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.language-name {
  color: colors.$color-select-letter;
}

.arrow {
  width: 20px;
  height: 20px;
  background: url('../../../assets/arrow.svg') no-repeat center center / contain;
}

.arrow-double {
  width: 20px;
  height: 20px;
  background: url('../../../assets/arrow_double.svg') no-repeat center center /
    contain;
}
