@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  align-self: stretch;
  gap: 40px;
  flex: 1 0 0;
  overflow: auto;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.select-translation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.select-button {
  width: 265px;

  & div {
    align-items: center;
    padding: 0 20px;

    & p {
      margin: 0px;
    }
  }

  .select-button-title {
    margin-top: 30px;
    font-size: styles.$font-size-dialog-title;
    font-weight: 600;
    line-height: 1.5rem;
    color: palettes.$palette-true-white;
  }

  .select-mic-button-image {
    margin: 20px 0;
    width: 220px;

    & img {
      max-width: 200px;
    }
  }

  .select-speaker-button-image {
    margin: 15px 0;
    width: 220px;

    & img {
      max-width: 200px;
    }
  }

  & button {
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-repeat: no-repeat;
    background-position: center;
    background-color: colors.$color-home-button;
    border-radius: 29.4px;
    border: none;

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }

    &:active {
      background-color: colors.$color-home-button-push;
    }
  }
}

.home::-webkit-scrollbar {
  width: styles.$scrollbar-width;
}

.home::-webkit-scrollbar-track {
  background: colors.$scrollbar-track-background-color;
}

.home::-webkit-scrollbar-thumb {
  background-color: colors.$color-list-view-scroll-bar;
  border-radius: styles.$scrollbar-thumb-radius;
}

.home::-webkit-scrollbar {
  width: styles.$scrollbar-width;
}

.home::-webkit-scrollbar-track {
  background: colors.$scrollbar-track-background-color;
}

.home::-webkit-scrollbar-thumb {
  background-color: colors.$color-list-view-scroll-bar;
  border-radius: styles.$scrollbar-thumb-radius;
}
