@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

.toastArea {
  /*配置*/
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  /*レイアウト*/
  max-width: 400px;
  min-width: 320px;
  border-radius: 5px;
  /*子要素の配置*/
  text-align: center;
  justify-content: center;
  display: flex;
  /*文字*/
  color: colors.$color-toast-text;
  font-size: styles.$font-size-toast-message;
  background-color: colors.$color-toast-bg;
  /*最上位に表示*/
  z-index: 100000;
}

.animation {
  animation-name: fadein;
  animation-duration: 5s; // 5秒後に消す
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    top: 0;
    opacity: 0;
  }
  15% {
    top: calc(styles.$header-height + 10px);
    opacity: 1;
  }
  85% {
    top: calc(styles.$header-height + 10px);
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 0;
  }
}

.toast-container {
  display: flex;
  padding: 10px 10px 10px 20px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.toast-message {
  display: flex;
  min-height: 24px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  & p {
    margin: 0;
  }
}

.toast-close-button {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;

  & button {
    border-radius: 50%;
    padding: 8px;
    background: transparent;
    border: none;
    &:hover {
      cursor: pointer;
      background-color: colors.$color-modal-close-button-hover;
    }
  }
}

.toast-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}
