@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/styles.module' as styles;
@use '../../../styles/mixins.module' as mixins;

.share-container {
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  max-height: 800px;
  @include mixins.dialog-scroll-style;

  @media screen and (max-height: styles.$share-dialog-max-screen-height) {
    & .share-qrcode {
      width: styles.$qr-code-mobile-size;
      height: styles.$qr-code-mobile-size;

      & canvas {
        width: styles.$qr-code-mobile-size;
        height: styles.$qr-code-mobile-size;
      }
    }
  }
}

.share-qrcode {
  border-radius: styles.$qr-code-border-radius;
  width: styles.$qr-code-size;
  height: styles.$qr-code-size;
  padding: 8px;
  margin-bottom: 10px;
  background-color: palettes.$palette-true-white;

  & canvas {
    border-radius: styles.$qr-code-border-radius;
    padding: 0 0;
    width: styles.$qr-code-size;
    height: styles.$qr-code-size;
  }
}

.share-column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.share-option-contents-wrapper {
  @include mixins.share-dialog-content-style;

  .csv-download-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;

    .csv-download-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
    }
  }
}

.share-title-wrapper {
  font-weight: 700;
  font-size: styles.$share-dialog-title-size;

  display: flex;
  align-items: flex-start;
  align-self: stretch;
}

.share-contents-description-title {
  font-weight: 700;
  font-size: styles.$share-dialog-descrption-size;
  line-height: styles.$share-dialog-line-height;

  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  text-align: left;
}

.share-contents-description-details {
  font-size: styles.$share-dialog-details-size;
  line-height: styles.$share-dialog-line-height;
  text-align: left;

  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  opacity: 0.7;
}

.share-description-content {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.url-copy-button-wrapper {
  display: flex;
  padding-left: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid
    var(--border-primary-on-surface, rgba(palettes.$palette-true-white, 0.7));

  .share-url-wrapper {
    text-overflow: ellipsis;
    flex: 1 0 0;
    text-align: left;
    font-size: styles.$share-dialog-common-button-font-size;
    line-height: 1rem;
    overflow: hidden;
    white-space: nowrap;
    color: var(
      --color-secondary-on-surface,
      rgba(palettes.$palette-true-white, 0.7)
    );
  }

  .url-copy-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-left: 1px solid
      var(
        --border-secondary-on-surface,
        rgba(palettes.$palette-true-white, 0.7)
      );
  }

  & button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 8px 16px;
    font-size: styles.$share-dialog-copy-button-font-size;
    background: transparent;
    border: none;

    color: colors.$color-copy-link;

    &:hover {
      cursor: pointer;
      color: colors.$color-copy-link-hover;
    }

    &:active {
      color: colors.$color-copy-link-active;
    }

    &:disabled {
      cursor: default;
      filter: saturate(0);
      background-color: colors.$color-modal-history-download-disable;
    }
  }
}

.danger-zone-wrapper {
  @include mixins.share-dialog-content-style;
  background: colors.$color-share-dialog-danger-zone;
}

.option-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;

  .option-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
  }
}

// 通訳履歴ダウンロードの許可の説明文
.csv-download-description-contents {
  width: 309px; // 値はFigmaから取得
}

// 共有終了の説明文
.share-end-description-contents {
  width: 192px; // 値はFigmaから取得
}

.suspension-button-wrapper {
  @include mixins.common_blue_button_style;

  & button {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    font-size: styles.$share-dialog-common-button-font-size;
    line-height: styles.$share-dialog-line-height;
    min-width: 168px;
    color: palettes.$palette-coral-red;
    background: none;
    border: 1px solid
      var(--border-destructive, colors.$color-destactive-button-border);
    font-weight: 400;

    &:hover {
      cursor: pointer;
      background-color: colors.$color-destactive-button-hover-gray;
    }

    &:active {
      background-color: colors.$color-destactive-button-active-gray;
    }
  }
}
